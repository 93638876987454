<template>
  <div class="mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
    <div class="bg-gray-100 p-4 border-b">
      <h2 class="text-xl font-semibold text-gray-800">
        Importer des utilisateurs via CSV
      </h2>
    </div>
    <form @submit.prevent="submitImport" class="p-6 space-y-4">
      <div>
        <label for="csvFile" class="block text-sm font-medium text-gray-700">
          Fichier CSV
        </label>
        <input
          type="file"
          id="csvFile"
          ref="csvFile"
          @change="handleFileChange"
          accept=".csv"
          required
          class="mt-1 block w-full text-sm text-gray-900 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
        />
      </div>
      <div class="flex items-center">
        <input
          type="checkbox"
          id="sendMail"
          v-model="sendMail"
          class="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
        />
        <label for="sendMail" class="ml-2 block text-sm text-gray-700">
          Envoyer un mail de notification
        </label>
      </div>
      <button
        type="submit"
        class="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Importer les utilisateurs
      </button>
    </form>
  </div>
</template>

<script>
import { http } from '@/services/api.service'

export default {
  name: 'UserCsvImportModal',
  data () {
    return {
      csvFile: null,
      sendMail: false
    }
  },
  methods: {
    handleFileChange (event) {
      const file = event.target.files[0]
      if (file) {
        this.csvFile = file
      }
    },
    submitImport () {
      if (!this.csvFile) {
        this.$notify({
          group: 'maviepro-error',
          text: 'Veuillez sélectionner un fichier CSV.'
        })
        return
      }
      const formData = new FormData()
      formData.append('file', this.csvFile)
      formData.append('send_mail', this.sendMail ? '1' : '0')
      http
        .post('/user/import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(({ data }) => {
          this.$notify({
            group: 'maviepro-success',
            text: data.message
          })
          this.$modal.hide('user-import-modal')
        })
        .catch(err => {
          console.error('Import error:', err)
          this.$notify({
            group: 'maviepro-error',
            text: "Erreur lors de l'import des utilisateurs. Veuillez réessayer."
          })
        })
    }
  }
}
</script>
