var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader',{attrs:{"title":(_vm.role + "s"),"description":("Gestion des " + _vm.role + "s"),"button":{
      icon: 'fas fa-plus-circle',
      text: ("Ajouter un " + _vm.role),
      action: _vm.create
    },"buttonBis":{
      icon: 'fas fa-file-import',
      text: "Importer des utilisateurs",
      action: _vm.openImportModal
    }}}),_c('UsersSubmenu'),_c('PageContent',[_c('div',{staticClass:"relative"},[_c('MVPTable',{key:_vm.$route.params.role,attrs:{"grid-data":_vm.gridData,"options":_vm.gridOptions},on:{"gridReady":_vm.onGridReady,"selectionChanged":function($event){_vm.selectedUsers = $event},"rowDoubleClicked":_vm.openModal}}),(_vm.$route.params.role === 'user')?_c('button',{staticClass:"absolute -bottom-12 right-2 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded",attrs:{"title":"Extraire la liste filtrée telle qu'elle est"},on:{"click":_vm.extractSelection}},[_vm._v(" Extraire la liste ")]):_vm._e()],1),(_vm.selectedUsers.length > 0)?_c('button',{staticClass:"button is-danger",on:{"click":_vm.deleteUsers}},[_c('i',{staticClass:"fas fa-trash"})]):_vm._e()]),_c('t-modal',{attrs:{"name":"user-form","variant":"contentForm"},on:{"before-open":_vm.modalOnBeforeOpen}},[(_vm.currentUser)?_c('MVPUsersForm',{attrs:{"user":_vm.currentUser,"userKey":_vm.currentUser.uuid,"role":_vm.$route.params.role,"groups":_vm.groups,"domains":_vm.domains},on:{"refresh":_vm.loadUsers}}):_vm._e()],1),_c('t-modal',{attrs:{"name":"user-import-modal","variant":"smallModal"}},[_c('UserCsvImportModal')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }